import React, { useState } from "react";
import Signature from "./Signature";

function TipografiaGenerator() {
  const [formData, setFormData] = useState({
    name: "Pedro Churro",
    jobTitle: "Programador Criativo",
    email: "pedro@tipo-grafia.com",
    website: "tipo-grafia.com",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>Email Signature Generator</h1>
      <form>
        <div>
          <label>Name: </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Job Title: </label>
          <input
            type="text"
            name="jobTitle"
            value={formData.jobTitle}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Email: </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Website: </label>
          <input
            type="text"
            name="website"
            value={formData.website}
            onChange={handleChange}
          />
        </div>
      </form>
      <Signature formData={formData} />
    </div>
  );
}

export default TipografiaGenerator;
