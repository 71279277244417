import React from "react";

function Signature({ formData }) {
  const { name, jobTitle, email, phone, address } = formData;

  const signatureHTML = `
    <table cellpadding="0" cellspacing="0" border="0" style="font-family: Arial, sans-serif; font-size: 14px; line-height: 1.5;">
        <tr>
            <td style="padding: 10px; vertical-align: middle;">
                <!-- Placeholder for Image -->
                <img src="/citropedralva/logo.jpeg" alt="Company Logo" style="width: 150px; height: auto;"/>
            </td>
            <td style="padding: 20px; vertical-align: middle; border-left: 2px solid #f0a000;">
                <!-- Signature Text -->
                <strong style="font-size: 16px;">${name}</strong><br/>
                <span style="color: grey;">${jobTitle}</span><br/><br/>
                <span style="color: grey;">
                    <img src="/citropedralva/mail.png" style="vertical-align: middle; width: 16px; height: 16px;"/> ${email}<br/>
                    <img src="/citropedralva/phone.png" style="vertical-align: middle; width: 16px; height: 16px;"/> ${phone}<br/>
                    <img src="/citropedralva/location.png" style="vertical-align: middle; width: 16px; height: 16px;"/> ${address}
                </span>
            </td>
        </tr>
    </table>
  `;

  return (
    <div>
      <h2>Generated Signature</h2>
      <div dangerouslySetInnerHTML={{ __html: signatureHTML }}></div>
    </div>
  );
}

export default Signature;
