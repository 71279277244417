import React, { useState } from "react";
import Signature from "./Signature";

function CSEGenerator() {
  const [formData, setFormData] = useState({
    name: "Benedita Coimbra",
    job:"Enoturismo",
    email: "beneditacoimbra@casasantaeulalia.pt",
    phone1: "(+351) 914 936 453 (Chamada para rede fixa nacional)",
    address: "Casa Santa Eulália | Atei | 4880-049 | Mondim de Basto",
    facebook: "https://www.facebook.com/casa.s.eulalia",
    instagram: "https://www.instagram.com/casasantaeulalia/"
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>Email Signature Generator</h1>
      <form>
        <div>
          <label>Name: </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Job: </label>
          <input
            type="text"
            name="job"
            value={formData.job}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Email: </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Phone 1: </label>
          <input
            type="text"
            name="phone1"
            value={formData.phone1}
            onChange={handleChange}
          />
        </div>
      </form>
      <Signature formData={formData} />
    </div>
  );
}

export default CSEGenerator;
