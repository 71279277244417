import React, { useState } from "react";
import Signature from "./Signature";

function FrutasTeresoGenerator() {
  const [formData, setFormData] = useState({
    name: "Diana Tereso Ferreira",
    jobTitle: "Qualidade · Quality",
    email: "diana@frutastereso.pt",
    phone1: "+351 912 873 074",
    phone2: "+351 282 575 260",
    address: "Ferrarias, 8365-084 Algoz, Silves",
    website: "frutastereso.pt",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>New Email Signature Generator</h1>
      <form>
        <div>
          <label>Name: </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Job Title: </label>
          <input
            type="text"
            name="jobTitle"
            value={formData.jobTitle}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Email: </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Phone 1: </label>
          <input
            type="text"
            name="phone1"
            value={formData.phone1}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Phone 2: </label>
          <input
            type="text"
            name="phone2"
            value={formData.phone2}
            onChange={handleChange}
          />
        </div>
      </form>
      <Signature formData={formData} />
    </div>
  );
}

export default FrutasTeresoGenerator;
