import React, { useState } from "react";
import Signature from "./Signature";

function IntermarcheGenerator() {
  const [formData, setFormData] = useState({
    name: "Pedro Henriques",
    email: "Pedromjh21@gmail.com",
    phone1: "241 360 830 (Chamada para rede fixa nacional)",
    phone2: "918 706 096 (Chamada para rede móvel nacional)",
    address: "Rua das Escolas | 42 | Alferrarede | 2200-042 | Abrantes",
    facebook: "https://www.facebook.com/intermarcheabrantes",
    instagram: "https://www.instagram.com/intermarcheabrantes/"
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>Email Signature Generator</h1>
      <form>
        <div>
          <label>Name: </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Email: </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Phone 1: </label>
          <input
            type="text"
            name="phone1"
            value={formData.phone1}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Phone 2: </label>
          <input
            type="text"
            name="phone2"
            value={formData.phone2}
            onChange={handleChange}
          />
        </div>
      </form>
      <Signature formData={formData} />
    </div>
  );
}

export default IntermarcheGenerator;
