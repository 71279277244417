import React from "react";

function Signature({ formData }) {
  const { name, email, job, phone1, address, facebook, instagram } = formData;

  const signatureHTML = `
      <table cellpadding="0" cellspacing="0" border="0" style="padding: 10px 0; font-size: 12px; color: #4a4a4a;">
        <tr>
          <td style="padding: 10px; vertical-align: middle;">
            <img src="/cse/logo.png" alt="Company Logo" style="width: 150px; height: auto;"/>
          </td>
          <td style="padding: 20px; vertical-align: middle;">
                <strong style="color:rgb(89, 89, 89); font-size: 12px; font-family: "Trebuchet MS", "Lucida Grande", sans-serif;">${name}</strong><br/>
                <span style="color:rgb(89, 89, 89); font-size: 12px; font-family: "Trebuchet MS", "Lucida Grande", sans-serif;">${job}</span><br/><br/>
            <div style="color:rgb(89, 89, 89); border-top:2px solid rgb(123, 129, 87); border-bottom:2px solid rgb(123, 129, 87);padding:10px 0;">
             <a href="mailto:${email}" style="color:rgb(89, 89, 89); font-size: 12px; font-family: "Trebuchet MS", "Lucida Grande", sans-serif;"> <img src="/cse/mail.png" style="vertical-align: middle; width: 16px; height: 16px;"/>${email}</a><br/>
              <a href="tel:${phone1}" style="color:rgb(89, 89, 89); font-size: 12px; font-family: "Trebuchet MS", "Lucida Grande", sans-serif;" ><img src="/cse/phone.png" style="vertical-align: middle; width: 16px; height: 16px;"/>${phone1}</a><br/>
              <span style="color:rgb(89, 89, 89); font-size: 12px; font-family: "Trebuchet MS", "Lucida Grande", sans-serif;" ><img src="/cse/address.png" style="vertical-align: middle; width: 16px; height: 16px;"/>${address}</span><br/>
            </div>
            <div style="padding-top: 10px;">
        <a href="${facebook}" style="text-decoration: none;">
          <img src="/cse/fb.png" style="vertical-align: middle; width: 20px; height: 20px;"/>
        </a>
        <a href="${instagram}" style="text-decoration: none;">
          <img src="/cse/ig.png" style="vertical-align: middle; width: 20px; height: 20px;"/>
        </a>
      </div>
          </td>
        </tr>
      </table>
`;

  return (
    <div>
      <h2>Generated Signature</h2>
      <div dangerouslySetInnerHTML={{ __html: signatureHTML }}></div>
    </div>
  );
}

export default Signature;
