import React from "react";

function Signature({ formData }) {
  const { name, jobTitle, email, phone1, phone2, address, website } = formData;

  const signatureHTML = `
    <div style="font-family: Courier, sans-serif; font-size: 12px; color: #4a4a4a; margin: 20px;width:450px">
    <div style="margin-bottom:1rem;">
      <strong style="font-size: 16px; color: #f0a000;">${name}</strong><br/>
      <span style="color: grey;">${jobTitle}</span>
      </div>
      <table cellpadding="0" cellspacing="0" border="0" style="width: 100%; border-top: 2px solid #f0a000; border-bottom: 2px solid #f0a000; padding: 10px 0;">
        <tr>
          <td style="padding: 10px; width: 125px;">
            <img src="/frutas/logo.jpeg" alt="Company Logo" style="width: 125px; height: auto;"/>
          </td>
          <td style="padding: 10px;">
            <div style="color: grey;height:100%;justify-content:space-between;row-gap:0.5rem">
              <span><img src="/frutas/mail.png" style="vertical-align: middle; width: 16px; height: 16px;"/> <a href="mailto:${email}" style="color: #f0a000; text-decoration: none;">${email}</a></span><br/>
              <span><img src="/frutas/phone.png" style="vertical-align: middle; width: 16px; height: 16px;"/> ${phone1}
              <span style="margin-left:20px;">${phone2 && `${phone2}`}</span></span><br/>
              <span><img src="/frutas/address.png" style="vertical-align: middle; width: 16px; height: 16px;"/> ${address}</span><br/>
              <span><img src="/frutas/link.png" style="vertical-align: middle; width: 16px; height: 16px;"/> <a href="http://${website}" style="color: #f0a000; text-decoration: none;">${website}</a></span><br/>
            </div>
          </td>
        </tr>
      </table>
      <div style="text-align: left; padding-top: 10px;">
        <a href="https://www.instagram.com/frutastereso/" style="text-decoration: none; margin-right: 10px;">
          <img src="/frutas/ig.png" style="vertical-align: middle; width: 30px; height: 30px;"/>
        </a>
        <a href="https://www.facebook.com/frutastereso/" style="text-decoration: none; margin-right: 10px;">
          <img src="/frutas/fb.png" style="vertical-align: middle; width: 30px; height: 30px;"/>
        </a>
        <a href="https://www.linkedin.com/company/frutastereso/" style="text-decoration: none;">
          <img src="/frutas/ld.png" style="vertical-align: middle; width: 30px; height: 30px;"/>
        </a>
      </div>
    </div>
  `;


  return (
    <div>
      <h2>Generated Signature</h2>
      <div dangerouslySetInnerHTML={{ __html: signatureHTML }}></div>
    </div>
  );
}

export default Signature;
