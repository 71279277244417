import React from "react";

function Signature({ formData }) {
    const { name, email, phone1, phone2, address, facebook, instagram } = formData;

    const signatureHTML = `
      <table cellpadding="0" cellspacing="0" border="0" style="padding: 10px 0; font-family: Arial, sans-serif; font-size: 14px; color: #4a4a4a;">
        <tr>
          <td style="padding: 10px; vertical-align: middle;">
            <img src="/inter/logo.jpeg" alt="Company Logo" style="width: 150px; height: auto;"/>
          </td>
          <td style="padding: 20px; vertical-align: middle;">
                <strong style="color:#000000; font-size: 16px;">${name}</strong><br/><br/>
            <div style="color: grey; border-top:2px solid #f44336; border-bottom:2px solid #f44336;padding:10px 0;">
             <a href="mailto:${email}" style="color: #f44336; text-decoration: none;"> <img src="/inter/mail.png" style="vertical-align: middle; width: 16px; height: 16px;"/> ${email}</a><br/>
              <span ><img src="/inter/phone.png" style="vertical-align: middle; width: 16px; height: 16px;"/> ${phone1}</span><br/>
              <span>${phone2 && `<img src="/inter/mobile.png" style="vertical-align: middle; width: 16px; height: 16px;"/> ${phone2}`}</span><br/>
              <span ><img src="/inter/address.png" style="vertical-align: middle; width: 16px; height: 16px;"/> ${address}</span><br/>
            </div>
            <div style="padding-top: 10px;">
        <a href="${facebook}" style="text-decoration: none;">
          <img src="/inter/fb.png" style="vertical-align: middle; width: 30px; height: 30px;"/>
        </a>
        <a href="${instagram}" style="text-decoration: none;">
          <img src="/inter/ig.png" style="vertical-align: middle; width: 30px; height: 30px;"/>
        </a>
      </div>
          </td>
        </tr>
      </table>
`;

    return (
        <div>
            <h2>Generated Signature</h2>
            <div dangerouslySetInnerHTML={{ __html: signatureHTML }}></div>
        </div>
    );
}

export default Signature;
