import React from "react";

function Signature({ formData }) {
  const { name, jobTitle, email} = formData;

  const signatureHTML = `
<table cellpadding="0" cellspacing="0" border="0" style="height: 100px;font-family: Arial, Helvetica, sans-serif; font-size: 12px; line-height: 1.5;">
    <tr>
        <td style="padding: 10px;">
            <!-- Signature Image -->
            <img src="logo.png" alt="Tipo-Grafia Logo" style="width: 150px; height: auto;"/>
        </td>
        <td style="padding: 10px;">
            <!-- Signature Text -->
            <strong style="font-family: Arial, Helvetica, sans-serif; font-size: 14px;">${name}</strong><br/>
            <span style="color: #7e7e7e;" >${jobTitle}</span><br/><br/>
            <span style="color: #7e7e7e; text-decoration: none;">${email}</span><br/>
            <a href="http://tipo-grafia.com" style="color: #0000ff; text-decoration: none;">tipo-grafia.com</a><br/>
            <br>
            <a style="text-decoration: none;" href="https://www.facebook.com/estudiotipografia">
                <img src="fb.png" width="30px" height="30px" />
            </a>
            <a style="text-decoration: none;" href="https://instagram.com/estudiotipo_grafia">
                <img src="ig.png" width="30px" height="30px" />
            </a>
        </td>
    </tr>
</table>
  `;

  return (
    <div>
      <h2>Generated Signature</h2>
      <div dangerouslySetInnerHTML={{ __html: signatureHTML }}></div>
    </div>
  );
}

export default Signature;
