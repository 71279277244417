import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TipografiaGenerator from "./generators/tipo-grafia/TipografiaGenerator";
import FrutasTeresoGenerator from "./generators/frutasTereso/FrutasTeresoGenerator";
import Citropedralva from "./generators/citropedralva/Citropedralva";
import IntermarcheGenerator from "./generators/intermarche/IntermarcheGenerator";
import CSEGenerator from "./generators/casasantaeulalia/CSEGenerator";

function App() {
  return (
    <Router>
      <div style={{ padding: "20px" }}>
        <Routes>
          <Route path="/tipo-grafia/" element={<TipografiaGenerator />} />
          <Route path="/frutastereso/" element={<FrutasTeresoGenerator />} />
          <Route path="/citropedralva/" element={<Citropedralva />} />
          <Route path="/intermarche/" element={<IntermarcheGenerator />} />
          <Route path="/casasantaeulalia/" element={<CSEGenerator />} />
          <Route path="/" element={<h1>Welcome to the Generator App</h1>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
